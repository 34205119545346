import axios, { type InternalAxiosRequestConfig } from 'axios';

const API = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isAxiosError(error)) {
      const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

      const { statusCode, message } = error.response?.data as {
        statusCode: number;
        message: string;
      };

      // If access token expired, refresh and retry the API call
      if (statusCode === 401 && message === 'Access token expired' && !originalRequest?._retry) {
        originalRequest._retry = true;
        await API.post('/authentication/refresh');
        return API(originalRequest);
      }

      if (
        [
          statusCode === 403 && message === 'Require admin role',
          statusCode === 401 && message === 'Access token expired' && originalRequest._retry,
        ].some(Boolean)
      ) {
        window.location.href =
          import.meta.env.VITE_ACCOUNT_PLATFORM_URL + '/' + 'playground' + '?error=session-expired';
      }
    }

    return Promise.reject(error);
  }
);

export default API;
